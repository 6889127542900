import React, { useEffect } from 'react';
import { isBrowser } from '../utils/isBrowser';
import Carculator from '../components/carculator/carculator-page';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  return (
    <Layout>
      <SEO
        title="Калькулятор доходности автомобиля на Getarent"
        description="Онлайн расчет дохода автомобиля в день, в месяц, в год. Узнайте сколько вы можете заработать, сдавая свой автомобиль в аренду на Гетарент"
      />
      <Carculator />
    </Layout>
  );
};
