const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const getBrands = (data) => {
  const allBrands = data.map((car) => car.brand);
  const uniqBrands = allBrands.filter(onlyUnique);
  const sortBrands = uniqBrands.sort();
  return sortBrands;
};

export const getModels = (brand, data) => {
  const filteredByBrand = data.filter((car) => car.brand === brand);
  const allModels = filteredByBrand.map((car) => car.model);
  const uniqModels = allModels.filter(onlyUnique);
  const sortModels = uniqModels.sort();
  return uniqModels;
};

export const getYears = (brand, model, data) => {
  const filteredData = data.filter((car) => car.brand === brand && car.model === model);
  const years = Object.keys(filteredData[0].productionYears);
  return years;
};

export const calculateValues = ({ brand, model, year, data }) => {
  const filteredData = data.find((car) => car.brand === brand && car.model === model);
  const { rentsQty, avRentsPerMonth, productionYears } = filteredData;
  const { recommendedPrice, minPrice, maxPrice } = productionYears[year];
  return { rentsQty, avRentsPerMonth, recommendedPrice, minPrice, maxPrice };
};
