import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik, Form } from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { track, yandexTracks, googleTracks } from '../../seo';
import CarIcon from '../../assets/icons/car-icon.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import WheelIcon from '../../assets/icons/wheel.svg';
import CarculatorData from '../../assets/data/calculator.json';
import MainImage from '../../assets/images/35.jpg';
import MobileMainImage from '../../assets/images/59.jpg';

import { getBrands, getModels, getYears, calculateValues } from './helper';

import { BackgroundWrapper, Card, CardContent, CardHeader } from '../../styledComponents';
import { Description, Name, SecondScreen, Subtitle, Title, Wrapper } from './styles';

export default ({ title, inline }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expanded, setExpanded] = useState(false);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [rentsQty, setRentsQty] = useState(0);
  const [avRentsPerMonth, setAvRentsPerMonth] = useState('');
  const [recommendedPrice, setRecommendedPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const brands = getBrands(CarculatorData);

  return (
    <Formik
      initialValues={{
        brand: '',
        model: '',
        year: '',
      }}
      onSubmit={({ brand, model, year }) => {
        setExpanded(true);
        track([yandexTracks.calculatorEstimate, googleTracks.calculatorEstimate]);

        const {
          rentsQty,
          avRentsPerMonth,
          recommendedPrice,
          minPrice,
          maxPrice,
        } = calculateValues({ brand, model, year, data: CarculatorData });
        setRentsQty(rentsQty);
        setAvRentsPerMonth(avRentsPerMonth);
        setRecommendedPrice(recommendedPrice);
        setMinPrice(minPrice);
        setMaxPrice(maxPrice);
        window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
      }}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Form className="form">
          <Accordion square expanded={expanded}>
            <AccordionSummary
              className="summury"
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <BackgroundWrapper
                className="first-screen"
                background={
                  isMobile ? `url(${MobileMainImage})` : `url(${MainImage}) center center`
                }
              >
                <Title className="title">{title}</Title>
                <Wrapper>
                  <FormControl>
                    <InputLabel htmlFor="brand">Выберите марку</InputLabel>
                    <Select
                      labelId="brand"
                      id="brand"
                      className="brand"
                      value={values.brand}
                      input={
                        <Input
                          startAdornment={
                            <InputAdornment position="start">
                              <CarIcon />
                            </InputAdornment>
                          }
                        />
                      }
                      onChange={({ target: { value } }) => {
                        setModels(getModels(value, CarculatorData));
                        setFieldValue('brand', value);
                        setFieldValue('model', '');
                        setFieldValue('year', '');
                      }}
                    >
                      {brands.map((brand) => (
                        <MenuItem key={`key-${brand}`} value={brand}>
                          {brand}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl disabled={!values.brand}>
                    <InputLabel htmlFor="model">Выберите модель</InputLabel>
                    <Select
                      labelId="model"
                      id="model"
                      className="model"
                      value={values.model}
                      input={
                        <Input
                          startAdornment={
                            <InputAdornment position="start">
                              <WheelIcon />
                            </InputAdornment>
                          }
                        />
                      }
                      onChange={({ target: { value } }) => {
                        setYears(getYears(values.brand, value, CarculatorData));
                        setFieldValue('model', value);
                        setFieldValue('year', '');
                      }}
                    >
                      {models.map((model) => (
                        <MenuItem key={`key-${model}`} value={model}>
                          {model}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl disabled={!(values.brand && values.model)}>
                    <InputLabel htmlFor="year">Выберите год</InputLabel>
                    <Select
                      labelId="year"
                      id="year"
                      value={values.year}
                      className="year"
                      input={
                        <Input
                          startAdornment={
                            <InputAdornment position="start">
                              <CalendarIcon />
                            </InputAdornment>
                          }
                        />
                      }
                      onChange={({ target: { value } }) => {
                        setFieldValue('year', value);
                      }}
                    >
                      {years.map((year) => (
                        <MenuItem key={`key-${year}`} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!(values.brand && values.model && values.year)}
                    onClick={submitForm}
                  >
                    Рассчитать
                  </Button>
                </Wrapper>
              </BackgroundWrapper>
            </AccordionSummary>
            <AccordionDetails className="details">
              <SecondScreen inline={inline}>
                <Subtitle>{`Ваш доход с ${values.brand} ${values.model} ${values.year} г`}</Subtitle>
                <Grid container>
                  <Grid item sm={12} md={6} lg={6} xl={3}>
                    <Card className="card">
                      <CardHeader className="header">
                        <Name>Рекомендуемая цена аренды в день, руб.</Name>
                      </CardHeader>
                      <CardContent className="content">
                        {recommendedPrice.toLocaleString('ru')}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xl={3}>
                    <Card className="card">
                      <CardHeader className="header">
                        <Name>Количество дней аренды в месяц</Name>
                      </CardHeader>
                      <CardContent className="content">{rentsQty}</CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xl={3}>
                    <Card className="card">
                      <CardHeader className="header">
                        <Name>Доход в месяц, руб.</Name>
                      </CardHeader>
                      <CardContent className="content">
                        {(recommendedPrice * rentsQty).toLocaleString('ru')}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6} lg={6} xl={3}>
                    <Card className="card">
                      <CardHeader className="header">
                        <Name>Доход в год, руб.</Name>
                      </CardHeader>
                      <CardContent className="content">
                        {(recommendedPrice * rentsQty * 12).toLocaleString('ru')}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Description>
                  {`Средняя цена аренды ${values.brand} ${values.model} от `}
                  <b>{minPrice.toLocaleString('ru')}</b> до <b>{maxPrice.toLocaleString('ru')}</b>{' '}
                  руб.
                </Description>
                <Description>{`Средняя загрузка ${values.brand} ${values.model} ${avRentsPerMonth}`}</Description>
                {!inline && (
                  <a rel="noreferrer" title="Зарегистрировать авто" href="/car-rental-income">
                    <Button variant="contained" color="primary" className="btn">
                      Как это все работает?
                    </Button>
                  </a>
                )}
              </SecondScreen>
            </AccordionDetails>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};
