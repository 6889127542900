import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const CarculatorPageWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-top: 50px;
    }

    .form {
      margin-bottom: 0;
    }

    .summury {
      padding: 0;
    }

    .summury > div {
      margin: 0;
      height: 100vh;
    }

    .first-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 70px;
      cursor: auto;
    }

    .title {
      max-width: 480px;
    }
  }

  @media ${breakpoints.laptop} {
    .title {
      max-width: 845px;
      text-align: left;
      padding-right: 250px;
      margin-left: -30px;
    }

    .first-screen {
      padding-top: 300px;
    }
  }
`;

export const CarculatorInlineWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-top: 0;
      width: 100%;
    }

    .form {
      margin-bottom: 0;
    }

    .summury {
      padding: 0;
    }

    .summury > div {
      margin: 0;
      height: 100vh;
    }

    .first-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 70px;
      cursor: auto;
    }

    .title {
      max-width: 480px;
    }
  }

  @media ${breakpoints.laptop} {
    .title {
      max-width: 845px;
      text-align: left;
      padding-right: 250px;
      margin-left: -30px;
    }

    .first-screen {
      padding-top: 300px;
    }
  }
`;

export const Title = styled.h1`
  @media ${breakpoints.mobile} {
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: ${(props) => props.color || '#222222'};
    margin-bottom: 15px;
  }

  @media ${breakpoints.laptop} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const Wrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      padding: 16px;
      background: #ffffff;
      border-radius: 4px;
    }

    /* TODO change to material ui class */
    & > div {
      margin-bottom: 15px;
      width: 100%;
    }

    #brand,
    #model,
    #year {
      padding-left: 45px;
      border-radius: 4px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: 500px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      flex-direction: row;
      width: auto;
    }

    /* TODO change to material ui class */
    & > div {
      width: auto;
    }

    #brand,
    #model {
      width: 180px;
      margin-right: 20px;
    }

    #year {
      width: 45px;
      margin-right: 20px;
    }
  }
`;

export const Description = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #222222;
    margin-bottom: 16px;
  }

  @media ${breakpoints.laptop} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const Name = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
`;

export const Subtitle = styled.h2`
  @media ${breakpoints.mobile} {
    font-size: 24px;
    color: #222222;
    margin: 30px 0 20px;
    text-align: center;
    font-weight: 700;
  }

  @media ${breakpoints.tablet} {
    margin: 50px 0 30px;
    font-size: 28px;
  }

  @media ${breakpoints.laptop} {
    margin: 70px 0 50px;
    font-size: 32px;
  }
`;

export const SecondScreen = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      text-align: center;
      margin-bottom: ${({ inline }) => (inline ? 0 : '50px')};
      scroll-margin-top: 50px;
    }

    .header {
      margin-bottom: 0;
      width: 100%;
      height: 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      color: #066bd6;
      font-size: 30px;
      font-weight: 800;
      height: 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card {
      width: 300px;
      margin: 0 auto 20px;
      height: 200px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      scroll-margin-top: 0;
    }
    .card {
      margin: 0 auto 40px;
    }
  }
`;

export const PreviewWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      padding: 50px 15px;
    }

    .container {
      width: 100%;
      position: relative;
      box-shadow: -8px -8px #edf1f7;
      border-radius: 12px;
    }

    .icon {
      position: absolute;
      z-index: 1;
    }

    .icon.-horizontal {
      top: -51px;
      right: -13px;
      width: 87px;
      height: 76.62px;
      transform: rotate(180deg);
    }

    .icon.-vertical {
      width: 88px;
      height: 77px;
      bottom: -45px;
      left: -14px;
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      text-align: left;
      color: #222222;
      margin: 40px 0 24px;
    }

    .btn {
      width: 100%;
      max-width: 330px;
      padding: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #ffffff;
    }

    .btn-icon {
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 50px 24px;
    }

    .btn {
      width: auto;
      max-width: none;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px 36px;
    }

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      margin: 0 0 40px;
    }

    .container {
      box-shadow: none;
    }

    .icon.-vertical {
      width: 218px;
      height: 192px;
      bottom: -83px;
      left: -98px;
    }

    .icon.-horizontal {
      width: 218px;
      height: 192px;
      top: -103px;
      right: -93px;
    }

    .btn {
      padding: 16px 32px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 100px 54px;
    }

    .container {
      width: auto;
    }
  }
`;

export const Box = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-radius: 12px;
      padding: 40px 15px;
      box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15);
      filter: drop-shadow(0px 4px 16px rgba(36, 93, 150, 0.15));
      z-index: 2;
      background: #ffffff;
    }

    .man-icon {
      width: 238px;
      height: 244px;
      margin: 0 auto;
    }

    .content {
      width: 100%;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: 50px;
      max-width: 1100px;
      margin: 0 auto;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 0;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      filter: none;
      box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15), -20px -20px #edf1f7;
    }

    .man-icon {
      width: 368px;
      height: 376px;
      flex: 1 0 368px;
      margin: 0 73px 0 78px;
    }

    .content {
      margin: 80px 0 auto 114px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 1107px;
      height: 422px;
      background: #ffffff;
    }
  }
`;

export const Text = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 160%;
    font-weight: 400;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    margin-bottom: 40px;
  }
`;

export const RegisterForm = styled.form`
  @media ${breakpoints.mobile} {
    & {
      font-weight: 400;
      text-align: start;
      width: 100%;
    }

    .phone-wrap .phone-input {
      width: 100%;
      box-sizing: border-box;
      color: #5d5d5d;
      border: 1px solid #8f9bb3;
      padding: 12px 16px 12px 48px;
      font-size: 16px;
      border-radius: 4px;
      height: 45px;
      margin: 0;
      display: block;
      min-width: 0;
      background: none;
      animation-name: mui-auto-fill-cancel;
      animation-duration: 10ms;
      -webkit-tap-highlight-color: transparent;

      &:focus-visible {
        border: 1px solid #066bd6;
        outline-style: none;
      }
    }

    .phone-wrap .special-label {
      display: none;
    }

    .phone-wrap .flag-dropdown {
      border-top: 1px solid #8f9bb3;
      border-bottom: 1px solid #8f9bb3;
      border-left: 1px solid #8f9bb3;
    }

    .autocomplete > div > div {
      padding-right: 0;
    }

    .autocomplete > div > div input {
      box-sizing: border-box;
      height: 45px;
      padding: 12px 16px !important;
    }

    .advice {
      margin-top: 40px;
    }

    .advice .text {
      width: 100%;
    }

    .label {
      display: block;
      font-family: 'Open Sans';
      margin: 16px 0 8px 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      margin-top: 32px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 25px;
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 100%;
    }

    .select-wrap {
      position: relative;
      width: 100%;
    }

    .select-wrap .native-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .select-wrap .MuiNativeSelect-icon {
      margin-right: 16px;
    }

    .native-select {
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      padding: 13.5px 16px;

      &.no-options-selected {
        color: rgb(168, 169, 169);
      }
    }

    .native-select:hover,
    .native-select:focus {
      border: 1px solid #066bd6;
      background-color: #ffffff;
      border-radius: 4px;
    }

    .option.-first {
      color: rgb(168, 169, 169);
    }

    .filepond--wrapper .filepond--list-scroller {
      height: 100%;
    }

    .licence-wrapper {
      display: inline-block;
      margin: 45px 0 0;
    }

    .licence-wrapper .licence {
      display: flex;
      align-items: flex-start;
      margin: 0;
    }

    .checkbox {
      padding: 0;
      margin-right: 10px;
    }

    .MuiFormControlLabel-root {
      margin-right: 5px;
      margin-top: -4px;
    }

    .PrivateSwitchBase-root-119 {
      padding: 0 9px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      font-weight: 400;
      text-align: start;
      margin: 0;
    }

    .label {
      display: block;
      margin: 15px 0 10px 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 190px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 24px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 20px;
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 420px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      font-weight: 400;
      text-align: start;
      margin: 0;
    }

    .label {
      display: block;
      font-family: 'Open Sans';
      margin: 16px 0 13px 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-text);
    }

    .btn {
      display: flex;
      width: 190px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 24px;
      color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(51, 36, 3, 0.08);
      border-radius: 4px;
      background-color: #006cde;
      &:disabled {
        color: #ffffff;
        background-color: #006cde;
        opacity: 0.5;
      }
      &:hover {
        background-color: #1973e8;
      }
    }

    .input-hint {
      font-weight: 400;
      color: var(--grey-text);
    }

    .hint-wrapper {
      padding-bottom: 10px;
      margin: 10px 0 4px 0;
    }

    .label:first-child {
      margin-top: 20px;
    }

    .hint-wrapper {
      padding-bottom: 10px;
    }

    .select {
      width: 420px;
    }
  }
`;

export const Message = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 120px;
    }

    & span {
      text-align: center;
    }
  }
`;

export const StyledError = styled.div`
  margin-top: 5px;
  color: red;
  width: 100%;
`;

export const DialogContainer = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 320px;
      margin: 0 auto;
    }

    .title {
      margin-bottom: 16px;
    }

    .text {
      margin-bottom: 20px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      width: 420px;
      margin: 0 24px;
    }
  }
`;
